<template>
  <div class="tabs">
    <!-- <div class="advert" @click="goActivity" >
       <img class="showImg" :src="advertitem" alt />
    </div> -->
    <ul class="list">
      <li
        class="item"
        v-for="(item,index) in tablist"
        :key="index" >
        <!-- <img class="iconfont"  :src="item.icon" alt  /> -->
        <span class="label">{{item.title}}</span>
      </li>
    </ul>
    <el-button class="cost-detail" type="primary" plain @click="constDetail">费用明细</el-button>
    <el-button class="account-detail" type="primary" plain @click="accountDetail">查看余额</el-button>

  </div>
</template>

<script>
  /*(function(){
    console.log("test111","embed.tawk.to->tab");
  })();*/
  import { message } from '@/utils/resetMessage';
  export default {
  props: ["tabCurrent","tablist"],
  computed: {
    current() {
      return this.tabCurrent;
    }
  },
  data() {
    return {
      //tabList: []
      iconitem:require("@/assets/images/download/ic1_1.png"),
      icondefault:require("@/assets/images/time.png")
    };
  },
  created() {
    //console.log("test111","tab-> created");
  },
  mounted() {
     //console.log("test111","tab-> mounted");
  },
  methods: {
    change(index,item) {
      if(item.status){
        this.$emit("change", index);
      }else{
        message.error({
          message: item.title+'网站正在维护中，请稍候',
          showClose: true,
          duration: 2000,
          offset: 100
        });
      }
    },
    //弹窗提示客服下载
    popServerDialog(){
      this.$emit("serverDialog");
    },
    //费用明细
    constDetail() {
      this.$emit("constDetail");
    },
    //费用明细
    accountDetail() {
      this.$emit("accountDetail");
    },
    goActivity() {
      this.$router.push({ path: "/activity" });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$tabItemBg: #fff; //f8f8fb
.tabs {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  .advert{
    float: contour;
    width: 90%;
    height: auto;
    margin-bottom: 15px;
    .showImg{
      width: 100%;
      height: 90%;
      cursor: pointer;
    }
  }
  .list {
    float: left;
    width: calc(100% - 110px);
    overflow: hidden;
    .item {
      margin: 0 18px 10px 0;
      width: 200px;
      height: 38px;
      line-height: 38px;
      display: inline-block;
      color: #333;
      background-color: #f3f4f9;
      border-radius: 100px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #0773fc !important;
      }
      &:last-child {
        .line {
          display: none;
        }
      }
      .iconfont {
        margin-right: 5px;
        vertical-align: middle;
      }
      .label {
        vertical-align: middle;
      }
    }
    .item-last {
      margin: 0 18px 10px 0;
      width: 200px;
      height: 38px;
      line-height: 38px;
      display: inline-block;
      color: #333;
      background-color: #f3f4f9;
      border-radius: 100px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #0773fc !important;
      }
    }
  }
  .cost-detail {
    position: absolute;
    right: 0;
    top: 0;
  }
  .account-detail {
    position: absolute;
    right: 0;
    top: 50px;
  }
  .account-kefu {
    position: absolute;
    right: 0;
    top: 100px;
  }
}
</style>
