<template>
  <div>
    <el-table :data="tableData" class="table">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="照片信息">
        <template slot-scope="scope" >
          <div class="fr">
            <p class="code">{{scope.row.id}}</p>
            <p class="label">
              {{scope.row.title}}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下载费用">
        <template slot-scope="scope">
          <div class="price" v-if="scope.row.complete">
            <p class="font-red">余额：{{scope.row.price}}元</p>
          </div>
          <div class="price" v-else>
            <p class="font-blue" v-if="scope.row.type">单价：{{scope.row.price}} 元
            <span v-if="scope.row.orgPrice"><del>(原价：{{scope.row.orgPrice}} 元)</del></span>
            </p>
            <p class="font-blue" v-else>{{scope.row.msg}}</p>
            <el-button
              type="danger"
              round
              v-if="scope.row.saleType !== null && scope.row.saleType !== ''"
              size="mini"
            >{{scope.row.saleType}}</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="图片格式">
        <template slot-scope="scope">
          <el-select v-model="scope.row.value" v-if="scope.row.type" :disabled="scope.row.disabled" placeholder="请选择">
            <el-option
                    v-for="item in scope.row.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作状态">
        <template slot-scope="scope">
          <div class="processBar" v-if="scope.row.loading"></div>
          <div v-else-if="!scope.row.loading && scope.row.complete"> <!-- @click="ordinaryDownload(scope.$index,scope.row)" :href="scope.row.downurl" target="_blank" -->
            <a rel="nofollow" ><el-button type="primary" size="small" @click="batchCopy($event)">点击复制下载链接（请复制到新的页面，粘贴打开下载）</el-button></a>
            </div>
          <div v-else-if="!scope.row.loading && scope.row.error">
              <p style="color:red;"> {{scope.row.msg}} </p>
          </div>
          <div v-else>
            <el-button type="text" size="middle" @click="deleteRow(scope.$index, tableData)"><i class="el-icon-close"></i></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :pager-count="11"
      layout="total, sizes, prev, pager, next"
      :total="tableData.length"
      v-if="tableData.length>100"
    ></el-pagination>
    <div class="btn">
       <el-button type="error" @click="batchCopy($event)" plain icon="el-icon-edit">一键复制下载链接（粘贴到新页面粘贴下载）</el-button>
    </div>
  </div>
</template>

<script>
import { hasBlance } from '../../../api/downLoad';
import { buy } from '../../../api/downLoad';
import { getSessionUser } from '../../../api/auth';
import { message } from '@/utils/resetMessage';
import Clipboard from 'clipboard';

export const downloadFile = (url) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";  // 防止影响页面
    iframe.style.height = 0;  // 防止影响页面
    iframe.src = url; 
    document.body.appendChild(iframe);  // 这一行必须，iframe挂在到dom树上才会发请求
    // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
    setTimeout(()=>{
      iframe.remove();
    }, 5 * 60 * 1000);
}

export default {
  props: ["data","sureflag","downflag","clearflag"],
  watch:{
    clearflag:{
      handler(val){
        if(val){
          this.urlArray = [];
        }
      }
    }
  },
  computed: {
    tableData() {
      return this.data;
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      urlArray:[], //批量下载
    };
  },
  mounted() {

  },
  methods: {
    popSuccess() {
      if (document.getElementsByClassName("el-message").length > 0) return;
      this.$message({
        message: "下载成功",
        type: "success",
        showClose: true,
        duration: 2000,
        offset: 80
      });
    },
    //普通下载
    ordinaryDownload(index, row) {
      //window.open(row.downurl,"_self");
      downloadFile(row.downurl);
    },
    //备用下载
    standbyDownload(index, row) {
      window.open(row.downurl2,"_self");
    },
    //迅雷下载
    thunderDownload(index, row) {
      console.log("thunderDownload: "+row.downurl);
      //this.thunderHref=row.downurl;
      console.log("thunderDownload: "+index );
    },
    batchCopy(e) {
        var text = '';
        this.tableData.forEach((item) => {
          var str = item.downurl;
          console.log(JSON.stringify(str));
          text += str + "\n";
        })
        
        const clipboard = new Clipboard(e.target, { text: () => text })
        clipboard.on('success', e => {
            message.success({
              message: '复制成功',
              duration: 2000,
              offset: 80
            })
            // 释放内存
            clipboard.off('error')
            clipboard.off('success')
            clipboard.destroy()
        })
        clipboard.on('error', e => {
            message.success({
              message: '复制失败，请重试',
              duration: 2000,
              offset: 80
            })
            // 释放内存
            clipboard.off('error')
            clipboard.off('success')
            clipboard.destroy()
        })
        clipboard.onClick(e)
    },
    //确认下载
    sureDownload() {
      this.$emit("onSureDown", false);
      this.$emit("onDownload", true);
      //判断是否够余额
      var ids = '';
      var type = '';
      this.tableData.forEach((item) => {
        if(item.type){
          ids+=item.id+',';
          type = item.webType;
          item.loading = true;
        }
      })
      ids = ids.substring(0, ids.length - 1);//去除最后一个逗号
      let dataform = {
        imageIDs:ids,
        webType:type
      }
      hasBlance(dataform).then(async (result) => {
        if(result){//如果余额足够，则开始获取下载链接
          let i = 0;
          while (i < this.tableData.length){
            let item = this.tableData[i];
            if(item.type){ //存在才搜索
              let flag = true;
              let buydata = {
                imageID:item.id,
                webType:item.webType,
                format:item.value
              }
              try {
                let res = await buy(buydata).catch(error => {
                  flag = false;
                });
                if(flag && (res.downloadUrl != '' && res.downloadUrl != null)){
                  item.complete = true;
                  item.loading = false;
                  item.downurl = res.downloadUrl;
                  item.downurl2 = res.downloadUrl2;
                  item.price = res.price;
                  this.urlArray.push(item.downurl); //批量
                }else{
                  item.loading = false;
                  item.error = true;
                  item.msg = '下载失败，请尝试重新下载';
                }
              }catch(e){
                item.loading = false;
                item.error = true;
                item.msg = '下载失败，未扣费，请您联系客服处理';
              }
            }
            if(this.tableData.length == (i+1)) { //判断是否最后一个
              this.$emit("onSureDown", true);
              this.$emit("onDownload", false);
              //更新余额
              getSessionUser({}).then((result) => {
                this.$store.dispatch("setUserInfo", result);
              }).catch((error) => {
                console.log(JSON.stringify(error));
              })
            }
            i++;
          }
        }else{//否则提示
          this.$emit("onDownload", false);
          message.success({
            message: '您的余额已不足，请充值',
            duration: 2000,
            offset: 80
          })
        }
      }).catch((error) => {
        //余额不足出异常时
        this.$emit("noBanlance", error);
        this.$emit("onDownload", false);
      })
    },
    //下载方法
    downloadOne(i){

    },
    //全部下载
    allDownload() {
      this.popSuccess();
    },
    //批量下载
    batchDownload() {
      for(var g = 0; g < this.urlArray.length; g++){
        window.open(this.urlArray[g]);
      }
    },
    deleteRow(index, row) {
      row.splice(index, 1);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
//进度条
.processBar {
  width: 240px;
  height: 7px;
  border: 1px solid #0059ca;
  border-radius: 2px;
  animation: progress-bar-stripes 2s linear infinite;
  background-color: #0773fc;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 20px 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  opacity: 1;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.table {
  .el-table__body {
    img {
      margin-right: 10px;
      float: left;
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    .fr {
      .code {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        color: #578ade;
        .label {
          font-size: 16px;
          color: #43424b;
        }
      }
      .size {
        padding-left: 10px;
        font-size: 10px !important;
      }
    }
    .el-button--mini.is-round {
      padding: 4px 10px;
      span {
        font-size: 10px;
      }
    }
    .price {
      .font-blue {
        color: #0773fc;
      }
      .font-red {
        color: #f56c6c;
      }
      .font-green {
        color: #67c23a;
      }
    }
  }
}
.btn {
  margin: 20px 0 100px;
  .el-button {
    margin-bottom: 20px;
    margin-left: 0;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    color: #0773fc;
    background: #fff;
    border-color: #0773fc;
    outline: none;
    i {
      font-size: 16px;
    }
  }
  .el-button--primary.is-plain:hover {
    background: #0773fc;
    border-color: #0773fc;
    color: #ffffff;
  }
}
.el-pagination {
  margin: 20px 0 30px;
}
</style>
