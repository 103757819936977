<template>
  <el-dialog title="费用明细" :visible.sync="visible" @close="closeDialog" class="table">
    <el-table :data="tableData" class="table">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="网站名称" prop="webType" class="font-bold"></el-table-column>
      <el-table-column label="下载费用" prop="price" class="font-red"></el-table-column>
      <el-table-column label="外网（需要翻墙）">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="small"
            @click="linkOverseas(scope.row)"
          >访问海外站</el-button>
        </template>
      </el-table-column>
      <el-table-column width="180" label="国内代理商（无需翻墙）">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="linkDomestic(scope.row)"
          >访问中文站</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      v-if="tableData.length > 10"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import {chargesList} from '../../../api/downLoad'
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val) this.getTableDatas();
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10
    };
  },
  methods: {
    getTableDatas() {
      chargesList().then((result) => {
        console.log(JSON.stringify(result));
        this.tableData = result;
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
    linkOverseas(row) {
      window.open(row.enUrl);
    },
    linkDomestic(row) {
      window.open(row.zhUrl);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
